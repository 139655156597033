import { Box, DialogActions, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { mergeDefaults } from 'components/Forms/utils';
import { useDialog, useConfig } from 'components/hooks';
import { StyledDialogContent, TopBackground } from 'components/Product/Create/styles';
import { InlineHelperText } from 'components/Product/InlineHelperText/InlineHelperText';
import { farmSchema, siteSchema } from 'constants/schemas/sites.schema';
import { DialogDefault, InfoTooltip, ThemeButton } from 'designSystem';
import { Form, Formik, FormikProps } from 'formik';
import { ICreateSiteInput, IUpdateSiteInput } from 'graphql/mutations/types/site-mutation.types';
import isEqual from 'lodash/isEqual';
import React, { FC, useRef, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { ImageVariant } from 'types/commonTypes';
import { IDefaultDialogProps } from 'types/dialog.types';
import { Partner } from 'types/partner.types';
import { IFarmSite, ISite, LandOwnershipType, SiteType } from 'types/sites.types';
import { Coordinates } from 'types/types';
import useSiteMutations from './hooks/useSiteMutations';
import SiteForm from './SiteForm';
import { AvailableLanguages, AvailableSizes } from 'types/enums';
import { LanguageSelectorItem, Loader } from 'components/Forms';
import { useQuery } from '@apollo/client';
import { GET_SITE } from 'graphql/queries/sites';

interface IAddEditSiteDialogProps extends IDefaultDialogProps {
  /** If passed its editing the existing site */
  site?: ISite;
  /**
   * Always assigns the site to this partner
   */
  partner?: Partner;
  /**
   * Will connect the site to this site cluster
   */
  siteClusterId?: string;
  /**
   * Callback function that is called when a site is created
   */
  onSiteCreated?: (site: ISite) => void;
}

interface FarmValues extends IFarmSite {
  rawMaterialId: string;
  size: number;
  numberOfFarmers?: number;
  weight?: number;
  additionalRawMaterialIds?: string[];
  landOwnership?: LandOwnershipType;
}

export interface DefaultFormValues {
  siteType: SiteType;
  title: string;
  locationCoordinates: Coordinates;
  locationName: string;
  image?: ImageVariant;
  partner?: Partner;
  externalId?: string;
  description?: string;
}

export type FarmFormValues = DefaultFormValues & { siteType: SiteType.FARM; farmData: FarmValues };

export type FormValues =
  | (DefaultFormValues & {
      siteType: Exclude<SiteType, SiteType.FARM>;
    })
  | FarmFormValues;

const StyledDialogActions = styled(DialogActions)<{ 'has-shadow': booleanish }>(
  ({ theme, 'has-shadow': hasShadow }) => ({
    padding: theme.spacing(3, 5, 4),
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,

    ...(hasShadow === 'true' && {
      padding: theme.spacing(3, 5),
      background: theme.custom.themeColors.white,
      boxShadow: theme.custom.shadows[4],
    }),
  })
);

const StyledSelect = styled(Select)(() => ({
  '& .MuiSelect-select ': {
    padding: '6px 12px !important',
  },

  '& .MuiButtonBase-root': {
    padding: 0,
    paddingRight: 20,
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

const StyledMenuItem = styled(MenuItem)(() => ({
  '& .MuiButtonBase-root': {
    padding: 0,
    paddingRight: 20,
  },
}));

const AddEditSiteDialog: FC<IAddEditSiteDialogProps> = ({
  site: siteProp,
  partner,
  open,
  siteClusterId,
  onClose,
  onSiteCreated,
}) => {
  const { createSite, updateSite } = useSiteMutations();
  const { openDialog } = useDialog();
  const { productLanguages } = useConfig();
  const [selectedLanguage, setSelectedLanguage] = useState<AvailableLanguages>(
    AvailableLanguages.ENGLISH
  );

  const { data: siteData, loading } = useQuery<{
    site: ISite;
  }>(GET_SITE, {
    variables: { id: siteProp?.id, lang: selectedLanguage },
    skip: selectedLanguage === AvailableLanguages.ENGLISH || !siteProp?.id,
    fetchPolicy: 'no-cache', // don't store in cache because it should not overwrite the componentOverview in the background
  });

  const site = selectedLanguage === AvailableLanguages.ENGLISH ? siteProp : siteData?.site;

  const isEdit = site !== undefined;

  const formRef = useRef<FormikProps<FormValues>>(null);
  const initialValues = mergeDefaults(siteSchema.default(), {
    title: site?.title,
    image: site?.image,
    partner: partner || site?.partner,
    siteType: site?.siteType,
    locationCoordinates: site?.locationCoordinates,
    locationName: site?.locationName,
    externalId: site?.externalId,
    description: site?.description,
    farmData:
      site?.siteType === SiteType.FARM
        ? mergeDefaults(farmSchema.default(), {
            rawMaterialId: site?.rawMaterialId,
            size: site?.size,
            numberOfFarmers: site?.numberOfFarmers,
            additionalRawMaterialIds: site?.additionalRawMaterialIds,
            landOwnership: site?.landOwnership,
          })
        : undefined,
  });

  const handleSubmit = async (values: FormValues) => {
    if (!isEdit) {
      const input: ICreateSiteInput = {
        siteType: values.siteType,
        title: values.title,
        image: values.image?.id ? { imageId: values.image.id } : undefined,
        partnerId: values.partner?.id || null,
        locationCoordinates: values.locationCoordinates,
        locationName: values.locationName,
        externalId: values.externalId,
        description: values.description,
        ...(values.siteType !== SiteType.CLUSTER && siteClusterId ? { siteClusterId } : {}),
        ...(values.siteType === SiteType.FARM ? { farmInput: values.farmData } : {}),
        ...(values.siteType === SiteType.CLUSTER ? { siteClusterInput: { siteIds: [] } } : {}),
      };
      const site = await createSite({ variables: { input, lang: selectedLanguage } });
      if (site.data?.createSite.site) {
        onSiteCreated?.(site.data.createSite.site);
      }
    } else {
      const input: IUpdateSiteInput = {
        siteType: values.siteType,
        title: values.title,
        image: values.image?.id ? { imageId: values.image.id } : null,
        partnerId: values.partner?.id || null,
        locationCoordinates: values.locationCoordinates,
        locationName: values.locationName,
        externalId: values.externalId || null,
        description: values.description || null,
        ...(values.siteType !== SiteType.CLUSTER && siteClusterId ? { siteClusterId } : {}),
        ...(values.siteType === SiteType.FARM ? { farmInput: values.farmData } : {}),
        ...(values.siteType === SiteType.CLUSTER ? { siteClusterInput: { siteIds: [] } } : {}),
      };
      await updateSite({ variables: { id: site.id, input, lang: selectedLanguage } });
    }
    onClose?.();
  };

  // If there are unsaved changes, ask the user if they want to leave the page
  const handleClose = () => {
    if (formRef.current && !isEqual(formRef.current.values, initialValues)) {
      openDialog({
        type: 'ALERT',
        props: {
          title: 'Unsaved changes',
          text: 'Are you sure you want to close this window? All unsaved changes will be lost and you will not be able to undo this action.',
          submitText: 'Close',
          itemTitle: formRef.current?.values?.title,
          displayCloseButton: true,
          onSubmit: onClose,
          onCancel: () => undefined,
        },
      });
    } else {
      onClose?.();
    }
  };

  const handleLanguageChange = (event: SelectChangeEvent<unknown>) => {
    setSelectedLanguage(event.target.value as AvailableLanguages);
  };

  return (
    <DialogDefault
      title={!site ? 'Add new site' : 'Edit Site'}
      data-cy="add-edit-site-dialog"
      open={open}
      fullWidth
      iconName={!site ? 'plus' : 'edit'}
      maxWidth="md"
      onClose={handleClose}
    >
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        enableReinitialize
        validateOnChange
        validationSchema={siteSchema}
        isInitialValid={false}
        initialStatus={{
          lang: selectedLanguage,
        }}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, isValid }) => (
          <Form>
            {loading && <Loader overlayOpacity />}
            <TopBackground />
            <Box
              position="absolute"
              display="flex"
              alignItems="center"
              top={30}
              right={42}
              zIndex={9}
            >
              <InfoTooltip
                size={AvailableSizes.SMALL}
                text="You can add and edit the component name in different languages. This can also be managed later on in the product page communication."
              />
              <StyledSelect
                label="Component name"
                variant="outlined"
                value={selectedLanguage}
                onChange={handleLanguageChange}
                data-cy="component-language-select"
              >
                {productLanguages.map(
                  ({ title, key, flag }: { title: string; key: string; flag: string }) => (
                    <StyledMenuItem
                      key={`product-lang-${key}`}
                      value={key}
                      data-cy="component-language-select-option"
                    >
                      <LanguageSelectorItem
                        value={key}
                        flag={flag}
                        title={title}
                        hoverBackground={false}
                        noPadding={undefined}
                      />
                    </StyledMenuItem>
                  )
                )}
              </StyledSelect>
            </Box>
            <StyledDialogContent>
              <Box display="flex" alignItems="center" mb={2} position="relative">
                <InlineHelperText
                  variant="INFO"
                  helperText="A site describes where a supply chain activity takes place, like a farm, processing plant or factory."
                />
              </Box>
              <SiteForm />
            </StyledDialogContent>
            <Box mt={7} />
            <StyledDialogActions has-shadow={Booleanish(values.siteType === 'FARM')}>
              <ThemeButton color="BLUE_ICE" size="large" onClick={handleClose}>
                Cancel
              </ThemeButton>
              <ThemeButton
                loading={isSubmitting}
                disabled={!isValid}
                color="YELLOW"
                size="large"
                type="submit"
              >
                Save
              </ThemeButton>
            </StyledDialogActions>
          </Form>
        )}
      </Formik>
    </DialogDefault>
  );
};

export default AddEditSiteDialog;
