import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Plus } from '@styled-icons/bootstrap';
import { Close } from '@styled-icons/evaicons-solid/Close';
import DeletableChip from 'components/Forms/DeletableChip';
import ThemeTypography from 'designSystem/Primitives/Typography/ThemeTypography';
import sortBy from 'lodash/sortBy';
import React, { ChangeEvent, FC, FocusEvent, KeyboardEvent, useMemo, useState } from 'react';
import { Booleanish, booleanish } from 'types/booleanish.types';
import { stopPropagation } from 'utils/event.utils';

export interface IAutocompleteSelectItem {
  id: string;
  title: string;
}

interface IAutocompleteSelectionProps {
  label: string;
  /**
   * The default searchTerm showed in the input
   */
  defaultSearchValue?: string;
  size?: 'small' | 'medium';
  /**
   * The items need to have an id and a title
   * Alphabetical sorting is applied on the list on the asc title
   */
  items: IAutocompleteSelectItem[];
  multiple?: boolean;
  /**
   * Determines whether the multiselect should automatically close after a selection is made.
   * Only affects multi select
   */
  closeAfterSelection?: boolean;
  defaultOpen?: boolean;
  selected?: string[] | string;
  enableAddNewItem?: boolean;
  itemName?: string;
  hasError?: boolean;
  errorMessage?: string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  'data-cy'?: string;
  onSelectionChange: (selectedItems?: string[] | string) => void;
  onAddNewItemClick: (value: string) => void;
  onSelectionClose?: (selectedItems?: string[] | string) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

const SIZE_RECORD: Record<'small' | 'medium', number> = {
  small: 30,
  medium: 40,
};

const StyledSelect = styled(Select)<{ size: 'small' | 'medium' }>(({ size }) => ({
  '&.MuiInputBase-root': {
    height: SIZE_RECORD[size],
  },
  '& .MuiSelect-select': {
    backgroundColor: '#fff',
    height: SIZE_RECORD[size],
  },
  '& .MuiChip-sizeSmall': {
    margin: 4,
  },
}));

const StyledSelectInputContainer = styled(Box)(() => ({
  // Width of the icon button need to be excluded
  maxWidth: 'calc(100% - 31px)',
  overflow: 'hidden',
  minHeight: '1.1876em',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  display: 'flex',
  alignItems: 'center',
}));

const Searchbar = styled(TextField)(({ theme }) => ({
  '&.MuiTextField-root': {
    padding: '0 8px 8px 8px',
    background: theme.custom.themeColors.white,
    '& .MuiInputBase-formControl': {
      paddingRight: 0,
    },
    '& .MuiInputBase-input': {
      padding: '10px 16px 10px',
    },
    '& .MuiInput-underline:before, & .MuiInput-underline:hover:before': {
      borderBottom: `1px solid ${theme.custom.themeColors.grayScale[40]}`,
    },
    '& .MuiInput-underline:after': {
      borderBottom: 0,
    },
  },
}));

const StyledLabel = styled(InputLabel)<{ styledSize: 'small' | 'medium' }>(
  ({ theme, styledSize }) => ({
    zIndex: 1,
    fontSize: 12,
    fontWeight: 500,
    color: `${theme.palette.grey[500]} !important`,
    '&.MuiInputLabel-outlined.MuiInputLabel-sizeSmall': {
      transform: `translate(14px, ${styledSize === 'small' ? 9 : 12}px) scale(1)`,
    },
  })
);

const StyledChip = styled(DeletableChip)<{ size: 'small' | 'medium' }>(({ size }) => ({
  ...(size === 'small' ? { height: 20, fontSize: 12 } : {}),
}));

const AddIcon = styled(Plus)(({ theme }) => ({
  color: theme.palette.secondary.main,
  height: 20,
}));

const StyledMenuItem = styled(MenuItem)<{ borderBottom: booleanish }>(
  ({ theme, borderBottom }) => ({
    borderBottom:
      borderBottom === 'true' ? `1px solid ${theme.custom.themeColors.grayScale[40]}` : 'none',
  })
);

const StyledFormHelperText = styled(FormHelperText)(() => ({
  '&.MuiFormHelperText-root': {
    marginTop: -2,
  },
}));

const AutocompleteSelection: FC<IAutocompleteSelectionProps> = ({
  label,
  defaultSearchValue = '',
  closeAfterSelection,
  items,
  multiple,
  hasError,
  size = 'medium',
  selected,
  enableAddNewItem,
  defaultOpen = false,
  itemName = 'item',
  errorMessage = 'This field is required',
  maxWidth = undefined,
  maxHeight = '45vh',
  'data-cy': dataCy = 'autocomplete-selection',
  onSelectionChange,
  onAddNewItemClick,
  onSelectionClose,
  onBlur,
}) => {
  const [searchValue, setSearchValue] = useState<string>(defaultSearchValue);
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);

  const sortedListItems = useMemo(
    () =>
      Array.isArray(selected)
        ? sortBy(
            items.filter(
              ({ id, title }) =>
                !selected.includes(id) &&
                ((searchValue &&
                  title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                  !searchValue)
            ),
            'title'
          )
        : sortBy(
            items.filter(
              ({ id, title }) =>
                id !== selected &&
                ((searchValue &&
                  title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())) ||
                  !searchValue)
            ),
            'title'
          ),
    [items, selected, searchValue]
  );

  const sortedSelectedListItems = useMemo(() => {
    if (!items || !selected) {
      return [];
    }
    return Array.isArray(selected)
      ? sortBy(
          items.filter(({ id }) => selected.includes(id)),
          'title'
        )
      : items.filter(({ id }) => selected === id);
  }, [items, selected]);

  const handleAddNewItemClick = () => onAddNewItemClick(searchValue);
  const clearSearchValue = () => setSearchValue('');

  const handleSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setSearchValue(event.target.value);
  };

  const handleMenuClose = (selection: string[] | string | undefined = selected) => {
    setIsOpen(false);
    onSelectionClose?.(selection);
    setSearchValue('');
  };

  const handleSearchKeyDown = (event: KeyboardEvent) => {
    if (event.key !== 'Escape') {
      event.stopPropagation();
    }
  };

  const handleSelectionChange = (event: SelectChangeEvent<unknown>) => {
    const selectedIds = event.target.value as string[];
    if (closeAfterSelection) {
      handleMenuClose(selectedIds);
    }
    onSelectionChange(selectedIds);
  };

  const handleRemove = (itemId: string) => {
    if (multiple && Array.isArray(selected)) {
      onSelectionChange(selected.filter(id => id !== itemId));
    } else {
      onSelectionChange(undefined);
    }
  };

  const getSelectedItem = (id: string) => items.find(item => item.id === id);

  const renderSelectedItems = (selected: unknown) => {
    if (multiple && Array.isArray(selected)) {
      return (
        <StyledSelectInputContainer>
          {(selected as string[]).map(id => {
            const item = getSelectedItem(id);
            return (
              item && (
                <StyledChip
                  key={id}
                  size={size}
                  label={<ThemeTypography variant="BODY_LARGE">{item.title}</ThemeTypography>}
                  onDelete={() => handleRemove(id)}
                />
              )
            );
          })}
        </StyledSelectInputContainer>
      );
    }
    const item = getSelectedItem(selected as string);
    return (
      item && (
        <StyledChip
          key={item.id}
          size={size}
          label={<ThemeTypography variant="BODY_LARGE">{item.title}</ThemeTypography>}
          onDelete={() => handleRemove(item.id)}
        />
      )
    );
  };

  const fullWidth = maxWidth === undefined;

  return (
    <FormControl fullWidth size={size} error={hasError}>
      {/* Workaround to show a placeholder since it is not supported on the Select component */}
      {!selected?.length && (
        <StyledLabel variant="outlined" styledSize={size} shrink={false}>
          {label}
        </StyledLabel>
      )}
      <StyledSelect
        data-cy={`${dataCy}_select`}
        variant="outlined"
        value={selected}
        multiple={multiple}
        size={size}
        MenuProps={{
          autoFocus: false,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          style: { maxWidth, maxHeight },
        }}
        open={isOpen}
        fullWidth={fullWidth}
        onChange={handleSelectionChange}
        renderValue={renderSelectedItems}
        onOpen={() => setIsOpen(true)}
        onClose={() => handleMenuClose()}
        onBlur={onBlur}
      >
        <Searchbar
          autoFocus
          fullWidth={fullWidth}
          data-cy={`${dataCy}_search`}
          value={searchValue}
          placeholder="Type to filter"
          onChange={handleSearchValueChange}
          onKeyDown={handleSearchKeyDown}
          // Prevent searchbar from being selected
          InputProps={{
            value: searchValue,
            endAdornment: (
              <InputAdornment position="start">
                <IconButton onClick={clearSearchValue}>
                  <Close size={16} />
                </IconButton>
              </InputAdornment>
            ),
            onClick: stopPropagation,
          }}
        />
        {sortedSelectedListItems.length &&
          !searchValue &&
          sortedSelectedListItems.map(({ id, title }, index) => (
            <StyledMenuItem
              key={id}
              value={id}
              selected
              data-cy={`${dataCy}_option-selected`}
              borderBottom={Booleanish(index === sortedSelectedListItems.length - 1)}
            >
              {title}
            </StyledMenuItem>
          ))}

        {sortedListItems.length &&
          sortedListItems.map(({ id, title }) => (
            <MenuItem key={id} value={id} data-cy={`${dataCy}_option`}>
              {title}
            </MenuItem>
          ))}

        {!sortedListItems.length && (
          <>
            <Box height={30} mt={2} ml={2}>
              <ThemeTypography variant="BODY_LARGE" color="GRAY_40">
                No results
              </ThemeTypography>
            </Box>
            {enableAddNewItem && (
              <MenuItem value="add-new-item" onClick={handleAddNewItemClick}>
                <AddIcon data-cy={`${dataCy}_add-new-btn`} />
                Add {`"${searchValue}"`} as new {itemName}
              </MenuItem>
            )}
          </>
        )}
      </StyledSelect>
      {hasError && <StyledFormHelperText>{errorMessage}</StyledFormHelperText>}
    </FormControl>
  );
};

export default AutocompleteSelection;
